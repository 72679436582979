import React from "react"
import PropTypes from "prop-types"

// Step progress indicator for form wizard

const FormProgress = ({ stepCount, currentStep }) => {
  const progressSteps = []

  // Create steps with appropriate classes
  for (let i = 1; i <= stepCount; i += 1) {
    let classes = "form-progress-step"

    if (i < currentStep) {
      classes += " done"
    } else if (i === currentStep) {
      classes += " current"
    }

    progressSteps.push(
      <li key={`step-${i}`} className={classes}>
        {" "}
        <span className="step-index">{i}</span>
      </li>
    )
  }

  return (
    <div className="form-progress flex flex-jc">
      <ol>{progressSteps}</ol>
    </div>
  )
}

FormProgress.propTypes = {
  stepCount: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default FormProgress
