import React from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import * as Yup from "yup"
import StepWizard from "react-step-wizard"
import axios from "axios"
import PropTypes from "prop-types"

import { RadioInput, SelectInput, InputFieldset } from "./formInputs"
import FormStepControls from "./formStepControls"
import FormProgress from "./formProgress"

// Options and values for form inputs
const attributeOptions = ["0", "1", "2", "3", "4", "5", "6", "7", "8"]
const likertValues = ["1", "2", "3", "4", "5"]
const rateViewOptions = [
  "Best not to mention it",
  "Below average",
  "Average",
  "Above average",
  "Best in Australia",
]
const rateQualityOptions = [
  "Derelict",
  "Below average",
  "Average",
  "Above average",
  "As good as it gets",
]
const rateComparedOptions = [
  "Worst on the street",
  "Below average",
  "Average",
  "Above average",
  "Best on the street",
]

// Wizard Step 1

const Step1 = props => (
  <section className="step step-1" aria-hidden={props.currentStep !== 1}>
    <InputFieldset legend="Are you the homeowner?">
      <RadioInput
        name="isHomeowner"
        value="yes"
        id="isHomeownerYes"
        key="homeowner-1"
      >
        Yes - it's mine
      </RadioInput>
      <RadioInput
        name="isHomeowner"
        value="no"
        id="isHomeownerNo"
        key="homeowner-2"
      >
        No - maybe one day
      </RadioInput>
    </InputFieldset>
    <FormStepControls {...props} />
  </section>
)

// Wizard Step 2

const Step2 = props => (
  <section className="step step-2" aria-hidden={props.currentStep !== 2}>
    <fieldset>
      <legend>Property Attributes</legend>
      <div className="flex flex-sb">
        <div className="step-inline-select">
          <SelectInput label="Beds" name="attrBed" id="attrBed" className="steps-select">
            {attributeOptions.map((option, index) => (
              <option key={`attr-bed-${index}`} value={option}>
                {option}
              </option>
            ))}
          </SelectInput>
        </div>

        <div className="step-inline-select">
          <SelectInput label="Bathrooms" name="attrBath" id="attrBath" className="steps-select">
            {attributeOptions.map((option, index) => (
              <option key={`attr-bath-${index}`} value={option}>
                {option}
              </option>
            ))}
          </SelectInput>
        </div>

        <div className="step-inline-select">
          <SelectInput label="Car spaces" name="attrCar" id="attrCar" className="steps-select">
            {attributeOptions.map((option, index) => (
              <option key={`attr-car-${index}`} value={option}>
                {option}
              </option>
            ))}
          </SelectInput>
        </div>

      </div>
    </fieldset>
    <FormStepControls {...props} />
  </section>
)

// Wizard Step 3

const Step3 = props => (
  <section className="step step-3" aria-hidden={props.currentStep !== 3}>
    <InputFieldset legend="Rate the property's aspect, outlook and/or views:">
      {rateViewOptions.map((option, index) => (
        <RadioInput
          name="rateView"
          value={likertValues[index]}
          id={`rate-view-${likertValues[index]}`}
          key={`rate-view-${likertValues[index]}`}
        >
          {option}
        </RadioInput>
      ))}
    </InputFieldset>
    <FormStepControls {...props} />
  </section>
)

// Wizard Step 4

const Step4 = props => (
  <section className="step step-4" aria-hidden={props.currentStep !== 4}>
    <InputFieldset legend="Rate the quality of the home and finishings:">
      {rateQualityOptions.map((option, index) => (
        <RadioInput
          name="rateQuality"
          value={likertValues[index]}
          id={`rate-quality-${likertValues[index]}`}
          key={`rate-quality-${likertValues[index]}`}
        >
          {option}
        </RadioInput>
      ))}
    </InputFieldset>
    <FormStepControls {...props} />
  </section>
)

// Wizard Step 5

const Step5 = props => (
  <section className="step step-5" aria-hidden={props.currentStep !== 5}>
    <InputFieldset legend="Rate the home compared to neighbouring properties:">
      {rateComparedOptions.map((option, index) => (
        <RadioInput
          name="rateCompared"
          value={likertValues[index]}
          id={`rate-compared-${likertValues[index]}`}
          key={`rate-compared-${likertValues[index]}`}
        >
          {option}
        </RadioInput>
      ))}
    </InputFieldset>
    <FormStepControls {...props} />
  </section>
)

// Form for updating property data

const PropertyDataForm = ({ propertyId }) => {
  const [currentStep, setCurrentStep] = React.useState(1)
  const [isError, setError] = React.useState(false)

  // Update the current step
  const handleStepChange = status => {
    setCurrentStep(status.activeStep)
  }

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={{
          isHomeowner: "yes",
          attrBed: "1",
          attrBath: "1",
          attrCar: "1",
          rateView: "3",
          rateQuality: "3",
          rateCompared: "3",
          propertyId,
        }}
        validationSchema={Yup.object({
          isHomeowner: Yup.string()
            .oneOf(["yes", "no"], "You must indicate if you are the homeowner")
            .required("Required"),
          attrBed: Yup.string()
            .oneOf(attributeOptions, "Invalid bedroom count")
            .required("Required"),
          attrBath: Yup.string()
            .oneOf(attributeOptions, "Invalid bathroom count")
            .required("Required"),
          attrCar: Yup.string()
            .oneOf(attributeOptions, "Invalid car space count")
            .required("Required"),
          rateView: Yup.string()
            .oneOf(
              likertValues,
              "You must rate the property's aspect, outlook and/or views"
            )
            .required("Required"),
          rateQuality: Yup.string()
            .oneOf(
              likertValues,
              "You must rate the quality of the home and furnishings"
            )
            .required("Required"),
          rateCompared: Yup.string()
            .oneOf(
              likertValues,
              "You must rate the home compared to neighbouring properties"
            )
            .required("Required"),
          propertyId: Yup.string().required("Required"),
        })}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          setError(false)
          axios
            .post(process.env.GATSBY_API_PROPERTY_FEEDBACK_ENDPOINT, {
              homeowner: values.isHomeowner,
              bedrooms: values.attrBed,
              bathrooms: values.attrBath,
              car_parks: values.attrCar,
              rate_aspect: values.rateView,
              rate_quality: values.rateQuality,
              rate_compared_to_neighbours: values.rateCompared,
              property_id: values.propertyId,
            })
            .then(() => {
              navigate(
                `/virtual-property-report/${propertyId}?beds=${values.attrBed}&baths=${values.attrBath}&car_parks=${values.attrCar}&property_type=${propertyId.split("-")[0]}`
              )
            })
            .catch(error => {
              setSubmitting(false)
              setError(true)
              console.log(error)
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormProgress stepCount={5} currentStep={currentStep} />
            <StepWizard onStepChange={handleStepChange} transitions={{}}>
              <Step1 />
              <Step2 />
              <Step3 />
              <Step4 />
              <Step5 isSubmitting={isSubmitting} isError={isError} />
            </StepWizard>
          </Form>
        )}
      </Formik>
    </div>
  )
}

PropertyDataForm.propTypes = {
  propertyId: PropTypes.string.isRequired,
}

export default PropertyDataForm
