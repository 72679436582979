import React from "react"
import { Router } from "@reach/router"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PropertyDataForm from "../components/forms/propertyDataForm"

// Page with form wizard

const DataFormPage = ({ propertyId }) => (
  <>
    <SEO title="Improve Property Data" />
    <div className="container">
      <div className="row">
        <div className="flex flex-jc">
          <PropertyDataForm propertyId={propertyId} />
        </div>
      </div>
    </div>
  </>
)

// Error page to handle incorrect or missing property id

const Data404Page = () => (
  <>
    <SEO title="404: Property Not found" />
    <h1>PROPERTY NOT FOUND</h1>
    <p>Sorry, that property doesn't appear to exist.</p>
  </>
)

// Gatsby page with router to handle dynamic routing to property

const PropertyDataPage = () => (
  <Layout>
    <Router basepath="/property-data">
      <DataFormPage path="/:propertyId" />
      <Data404Page default />
    </Router>
  </Layout>
)

export default PropertyDataPage
