import React from "react"
import PropTypes from "prop-types"

// Step controls for form wizard
// Previous, next and if last step in wizard submit

const FormStepControls = ({
  previousStep,
  totalSteps,
  currentStep,
  nextStep,
  isSubmitting,
  isError,
}) => (
  <div className="step-controls flex flex-end">
    {currentStep > 1 && (
      <button type="button" onClick={previousStep} className="btn btn-grey">
        Previous
      </button>
    )}

    {currentStep !== totalSteps && (
      <button type="button" className="btn btn-orange" onClick={nextStep}>
        Next
      </button>
    )}

    {currentStep === totalSteps && (
      <>
        <button type="submit" className="btn btn-blue" disabled={isSubmitting}>
          {!isSubmitting && <span>Submit Data</span>}
          {isSubmitting && (
            <span className="form-submitting">Submitting...</span>
          )}
        </button>
        {isError && (
          <div className="form-submitted-error">
            There was an error submitting your data.
          </div>
        )}
      </>
    )}
  </div>
)

FormStepControls.defaultProps = {
  isSubmitting: false,
  isError: false,
}

FormStepControls.propTypes = {
  previousStep: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isError: PropTypes.bool,
}

export default FormStepControls
